// 第三方合作路由
export default [
  {
    path: '/partner/xsw',
    component: () => import('@/views/pages/partner/xsw'),
  },
  {
    path: '/partner/qh/rule',
    component: () => import('@/views/pages/partner/qinghua/rule'),
  },
];

// 收集个人信息路由配置

export const configRouter = [
  {
    path: '/information/user',
    component: () => import('@/views/pages/information/user'),
    meta: { title: '个人信息' },
    children: [
      {
        path: 'home',
        name: 'Home',
        component: () => import('@/views/pages/information/user/home'),
        meta: {
          permission: true,
          title: '个人信息收集清单',
        },
      },
      {
        path: 'details',
        name: 'Details',
        component: () => import('@/views/pages/information/user/details'),
        meta: {
          permission: true,
          title: '基本信息',
        },
      },
    ],
  },
  {
    path: '/information/bm',
    component: () => import('@/views/pages/information/bm'),
    meta: { title: '个人信息' },
  },
  {
    path: '/information/privacy',
    component: () => import('@/views/pages/information/privacy'),
    meta: { title: '隐私政策概要' },
  },
  {
    path: '/information/pguide',
    component: () => import('@/views/pages/information/pguide'),
    meta: { title: '未成年人隐私政策' },
  },
  {
    path: '/information/zz',
    component: () => import('@/views/pages/information/zz'),
    meta: { title: '资质' },
    children: [
      {
        path: '/information/zz/home',
        component: () => import('@/views/pages/information/zz/home'),
        meta: { title: '资质' },
      },
      {
        path: '/information/zz/image',
        component: () => import('@/views/pages/information/zz/image'),
        name: 'Image',
        meta: { title: '资质' },
      },
    ],
  },
  {
    path: '/activity/recommend',
    component: () => import('@/views/pages/activity/recommend/index'),
    meta: { title: '种草赢会员' },
  },
  {
    path: '/tools/data',
    component: () => import('@/views/pages/tools/data'),
    meta: { title: '数据处理' },
  },
  {
    path: '/tools/guide',
    component: () => import('@/views/pages/tools/guide'),
    meta: { title: '数据功能使用手册' },
  },
  {
    path: '/magic/help',
    component: () => import('@/views/pages/magic/help'),
    meta: { title: '自动记账' },
  },
  {
    path: '/lite/magic/help',
    component: () => import('@/views/pages/magic/help'),
    meta: { title: '自动记账' },
  },
  {
    path: '/magic/use',
    component: () => import('@/views/pages/magic/use'),
    meta: { title: '自动记账' },
  },
  {
    path: '/magic/intro',
    component: () => import('@/views/pages/magic/intro'),
    meta: { title: '自动记账教程' },
  },
  {
    path: '/lite/magic/intro',
    component: () => import('@/views/pages/magic/intro'),
    meta: { title: '自动记账教程' },
  },
];
